/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
    query GetProfile($country_code: COUNTRY_CODE!) {
        getProfile(country_code: $country_code) {
            company
            email
            family_name
            name
            view
        }
    }
`;
export const listProspects = /* GraphQL */ `
    query ListProspects(
        $country_code: COUNTRY_CODE!
        $sort: SORT
        $sort_field: PROSPECT_SORT_FIELD
        $status: STATUS
        $from: Int!
        $size: Int!
        $search_term: String
    ) {
        listProspects(
            country_code: $country_code
            sort: $sort
            sort_field: $sort_field
            status: $status
            from: $from
            size: $size
            search_term: $search_term
        ) {
            total
            prospects {
                id
                timeframe
                address
                postal_code
                type
                expected_date
                occupancy
                docs {
                    link
                    name
                    type
                    category
                }
                remark
                status
                created
                delivery
                polygon {
                    lng
                    lat
                }
                location
                quote {
                    capacity
                    cost
                    panel_number
                    module
                    inverter
                    inverter_number
                    scaffolding
                    generated
                    comment
                    docs {
                        link
                        name
                        type
                        category
                    }
                    reports {
                        date
                        text
                    }
                }
                index
                comment
            }
        }
    }
`;
export const getUploadUrl = /* GraphQL */ `
    query GetUploadUrl($input: [File!]!) {
        getUploadUrl(input: $input) {
            url
            link
            name
        }
    }
`;
export const getProspect = /* GraphQL */ `
    query GetProspect($country_code: COUNTRY_CODE!, $id: String) {
        getProspect(country_code: $country_code, id: $id) {
            id
            timeframe
            address
            postal_code
            type
            expected_date
            occupancy
            docs {
                link
                name
                type
                category
            }
            remark
            status
            created
            delivery
            polygon {
                lng
                lat
            }
            location
            quote {
                capacity
                cost
                panel_number
                module
                inverter
                inverter_number
                scaffolding
                generated
                comment
                docs {
                    link
                    name
                    type
                    category
                }
                reports {
                    date
                    text
                }
            }
            index
            comment
        }
    }
`;
export const getAssetsUrl = /* GraphQL */ `
    query GetAssetsUrl($url: String!) {
        getAssetsUrl(url: $url)
    }
`;
