//type
import { GetAssetsUrlQuery } from '@interfaces/graphql/API';

//library
import { GraphQLResult } from '@aws-amplify/api';
import { API } from 'aws-amplify';

//code
import { getAssetsUrl } from '@components/graphql/queries';

/**
 * Call getS3Url to get temporary for the s3 object
 *
 * @param {string} objectLink - The link of the s3 object
 */
const showAssets = async (objectLink: string): Promise<void> => {
    try {
        const response = (await API.graphql({
            query: getAssetsUrl,
            variables: { url: objectLink }
        })) as GraphQLResult<GetAssetsUrlQuery>;

        let tempUrl = '';
        if (response.data && response.data.getAssetsUrl) {
            tempUrl = response.data.getAssetsUrl;
        }

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = tempUrl;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
    } catch {
        return;
    }
};

export default showAssets;
