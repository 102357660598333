//type
import { HeaderProps } from '@interfaces/layout/Header';

//Library
import React, { ReactElement, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useClickAway } from 'react-use';

//code
import useCurrentInitial from '@components/layout/helpers/useCurrentUserInitials';
import SignOut from '@components/layout/helpers/layout/SignOut';

/**
 * Create the header on the page
 *
 * @param {HeaderProps} props - The search input and the type of header we want
 * @returns {ReactElement} - The header
 */
const Header: React.FC<HeaderProps> = ({ childRef }: HeaderProps): ReactElement => {
    const initials = useCurrentInitial();
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

    /** Allow to set the menu state at false when we click outside of it. */
    const dropdownRef = useRef(null);
    useClickAway(dropdownRef, () => {
        setIsMenuVisible(false);
    });

    return (
        <header ref={childRef} className="fixed z-40 bg-white w-screen print:relative print:mt-8">
            <nav className="flex items-center px-6 py-4 print:py-2">
                <Link href="/" passHref>
                    <a href="replace" className="w-40 pt-1 print:mx-auto">
                        <Image src="/solarai_logo.png" alt="Solar Ai" width="160" height="35" />
                    </a>
                </Link>
                <div className="flex items-center ml-auto  print:hidden">
                    <div ref={dropdownRef}>
                        <div>
                            <button
                                className="flex text-sm rounded-full focus:outline-none hover:shadow-outline focus:shadow-outline transition duration-150 ease-in-out h-10 w-10 items-center justify-center bg-elements text-white font-medium uppercase"
                                aria-label="User menu"
                                aria-haspopup="true"
                                onClick={() => setIsMenuVisible(!isMenuVisible)}>
                                {initials}
                            </button>
                        </div>
                        <div
                            className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg transition-opacity duration-500 ease-in-out ${
                                isMenuVisible ? 'opacity-100' : 'opacity-0'
                            }`}>
                            {isMenuVisible && (
                                <div
                                    className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="user-menu">
                                    <Link href="/account/profile">
                                        <a
                                            className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                            role="menuitem">
                                            Your Profile
                                        </a>
                                    </Link>
                                    <SignOut />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
