// type
import { Doc, DOC_CATEGORY } from '@interfaces/graphql/API';
import { DocsObject, UseDocsProps, UseDocsReturn } from '@interfaces/quote/helpers/hook/useDocs';

// library
import { useState, useEffect } from 'react';

/**
 * Categorize the docs
 *
 * @param {UseDocsProps} props - The props
 * @returns {DocsObject} - The docs data
 */
const useDocs = ({ docs }: UseDocsProps): UseDocsReturn => {
    const [document, setDocument] = useState<DocsObject>({
        [DOC_CATEGORY.METER]: [],
        [DOC_CATEGORY.ROOF]: [],
        [DOC_CATEGORY.PLAN]: [],
        [DOC_CATEGORY.SWITCHBOARD]: []
    });

    useEffect(() => {
        /**
         * Reduce the doc array by categories
         *
         * @param {DocsObject} result - The result object
         * @param {Doc} data - The doc object
         * @returns {DocsObject} - The docs objects.
         */
        const reducer = (result: DocsObject, data: Doc): DocsObject => {
            const item: Doc = { ...data };
            data.category && result[data.category].push(item);
            return result;
        };

        if (docs) {
            const result = docs.reduce(reducer, {
                [DOC_CATEGORY.METER]: [],
                [DOC_CATEGORY.ROOF]: [],
                [DOC_CATEGORY.PLAN]: [],
                [DOC_CATEGORY.SWITCHBOARD]: []
            });
            setDocument(result);
        }
    }, [docs]);

    return { document, setDocument };
};

export default useDocs;
