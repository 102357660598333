//type
import { Doc, DOC_CATEGORY } from '@interfaces/graphql/API';
import { ProspectsDocsProps } from '@interfaces/layout/ProspectsDocs';

//Library
import React, { ReactElement } from 'react';

//code
import Document from '@components/prospects/helpers/layout/Document';
import useDocs from '@components/quote/helpers/hooks/useDocs';

/**
 * Create the prospect document
 *
 * @param {ProspectsDocsProps} props - The props
 * @returns {ReactElement} - The ProspectsDocs
 */
const ProspectsDocs: React.FC<ProspectsDocsProps> = ({
    docs
}: ProspectsDocsProps): ReactElement => {
    const { document } = useDocs({ docs: docs });
    const label = [
        { title: 'Meter box', index: DOC_CATEGORY.METER },
        { title: 'Switchboard', index: DOC_CATEGORY.SWITCHBOARD },
        { title: 'Roof picture', index: DOC_CATEGORY.ROOF },
        { title: 'Roof plan', index: DOC_CATEGORY.PLAN }
    ];

    return (
        <>
            {label.map((label, index: number) => (
                <div key={index} className="py-1">
                    <div className="text-navy font-medium text-sm" aria-label="doc_type">
                        {label.title}:
                    </div>
                    {document[label.index].length ? (
                        <>
                            {document[label.index].map((doc: Doc, index: number) => (
                                <div key={index} className="flex justify-start">
                                    <Document
                                        data={{
                                            link: doc.link,
                                            type: doc.type,
                                            name: doc.name
                                        }}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <>&mdash;</>
                    )}
                </div>
            ))}
        </>
    );
};

export default ProspectsDocs;
