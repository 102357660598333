import { BUILDING_TYPE } from '@interfaces/graphql/API';

const propertyType: { [key in keyof typeof BUILDING_TYPE]: string } = {
    LANDED_HOUSE: 'Landed house',
    CONDOMINIUM: 'Condominium/Strata',
    HDB_FLAT: 'HDB flat',
    RENTED_COMMERCIAL_PROPERTY: 'Rented commercial property',
    OWNED_COMMERCIAL_PROPERTY: 'Owned commercial property'
};

export default propertyType;
