//types
import { DocumentProps } from '@interfaces/prospects/helpers/layout/Document';

//library
import React, { ReactElement } from 'react';

//code
import showAssets from '@components/helpers/module/showAssets/ShowAssets';

/**
 * The upload document component
 *
 * @param {DocumentProps} props - The props
 * @returns {ReactElement} - The component.
 */
const Document: React.FC<DocumentProps> = ({ data }: DocumentProps): ReactElement => (
    <>
        {data.link == 'none' ? (
            ''
        ) : (
            <div
                aria-label="doc"
                className="inline-flex items-center bg-badge-gray rounded-md p-2 mt-3 space-x-1 cursor-pointer"
                onClick={() => showAssets(data.link)}
                role="presentation">
                {data.type.indexOf('image') > -1 ? (
                    <svg
                        width="24"
                        height="24"
                        aria-label="image"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="#D9D9D9"
                            d="M534 352V136H232v752h560V394H576a42 42 0 0 1-42-42zm-134 50c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40zm296 294H328.1c-6.7 0-10.4-7.7-6.3-12.9l99.8-127.2a8 8 0 0 1 12.6 0l41.1 52.4 77.8-99.2a8.1 8.1 0 0 1 12.7 0l136.5 174c4.1 5.2.4 12.9-6.3 12.9z"
                        />
                        <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM602 137.8L790.2 326H602V137.8zM792 888H232V136h302v216a42 42 0 0 0 42 42h216v494z" />
                        <path d="M553.1 509.1l-77.8 99.2-41.1-52.4a8 8 0 0 0-12.6 0l-99.8 127.2a7.98 7.98 0 0 0 6.3 12.9H696c6.7 0 10.4-7.7 6.3-12.9l-136.5-174a8.1 8.1 0 0 0-12.7 0zM360 442a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
                    </svg>
                ) : (
                    <svg
                        aria-label="document"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
                            stroke="#002558"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M14 2V8H20"
                            stroke="#002558"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16 13H8"
                            stroke="#002558"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16 17H8"
                            stroke="#002558"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10 9H9H8"
                            stroke="#002558"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
                <div className="text-xs text-saigray-text">
                    {data.name.length > 15
                        ? data.name.substring(0, 6) +
                          '...' +
                          data.name.substring(data.name.length - 6)
                        : data.name}
                </div>
            </div>
        )}
    </>
);

export default Document;
