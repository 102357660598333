//library
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

/**
 * A custom hook to get the user initials from congito.
 *
 * @returns {string} - The user initials
 */
const useCurrentUserInitial = (): string => {
    const [initial, setInitial] = useState<string>(' ');

    useEffect(() => {
        const getUserInfo = async () => {
            const user = await Auth.currentUserInfo();
            const initial = `${user.attributes.name.charAt(0)}${user.attributes.family_name.charAt(
                0
            )}`;
            setInitial(initial);
        };
        getUserInfo();
    }, []);

    return initial;
};

export default useCurrentUserInitial;
